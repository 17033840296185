import React from 'react'
import { graphql } from 'gatsby'
import { Box, Heading } from 'theme-ui'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FaClock, FaCalendarDay } from 'react-icons/fa'
import slugify from '../helpers/slugify'
import PageHero from '../components/UI/PageHero'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const BlogMenu = ({ data }) => {
  const blogData = data.allContentfulBlogPosts.nodes

  const heroBackgroundImage =
    'https://res.cloudinary.com/dpakxm3nm/image/upload/portfolio/blog-hero.jpg'
  return (
    <Box sx={styles.container}>
      <PageHero
        heroTitle={'The Blog'}
        heroSubtitle={'Sharing My Knowledge Through Articles'}
        backgroundImage={heroBackgroundImage}
      />
      <Box sx={styles.blogsContainer}>
        {blogData.map((blog) => {
          const {
            // id,
            blogTitle,
            // categories,
            blogDescription,
            blogContent,
            // updatedAt,
            publishedDate,
            blogMainImage,
          } = blog

          return (
            <Box sx={styles.blog}>
              <AniLink
                to={`/blog/${slugify(blogTitle)}`}
                rel='noopener'
                paintDrip
                duration={0}
                hex='#e01f5a'
              >
                {/* <GatsbyImage sx={styles.blogImage} image={getImage(blogMainImage)}src={blogMainImage.file.url} /> */}
                <Box sx={styles.blogImage}>
                  <GatsbyImage
                    // sx={styles.blogImage}
                    image={getImage(blogMainImage)}
                  />
                </Box>

                <Box sx={styles.content}>
                  <Heading as='h1' sx={styles.blogTitle}>
                    {blogTitle}
                  </Heading>

                  <Heading
                    as='h2'
                    sx={styles.blogDescription}
                    dangerouslySetInnerHTML={{
                      __html: blogDescription.childMarkdownRemark.html,
                    }}
                  />

                  <Box sx={styles.details}>
                    <Box sx={styles.detail}>
                      <FaClock />{' '}
                      {`${blogContent.childMarkdownRemark.timeToRead} min read`}
                    </Box>
                    <Box sx={styles.detail}>
                      <FaCalendarDay /> {`${publishedDate}`}
                    </Box>
                  </Box>
                </Box>
              </AniLink>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

const styles = {
  container: {},
  hero: {
    height: '80vh',
    img: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },
  blogsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0rem 0.5rem',
    marginTop: '-0.5rem',
    '> div:nth-of-type(4n+1), > div:nth-of-type(4n+4)': {
      width: ['', '', 'calc(33.3% - 1rem * 2)'],
    },
    '> div:nth-of-type(4n+2), > div:nth-of-type(4n+3)': {
      width: ['', '', 'calc(66.6% - 1rem * 2)'],
    },
  },
  blog: {
    width: ['100%', '', ''],
    margin: ['0.5rem', '', '0.5rem 0.5rem 1rem'],
    position: 'relative',
    overflow: 'hidden',
    height: '500px',
    flexGrow: '1',
    borderRadius: '3px',
    ':hover': {
      img: {
        transform: 'scale(1.2)',
        // filter: 'brightness(1)',
      },
    },
    a: {
      '::after': {
        content: "''",
        background:
          'linear-gradient(193deg, rgba(46,59,73,0) 0%, rgba(49,55,62,0) 35%, rgba(0,0,0,1) 100%)',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0rem',
        left: '0rem',
      },
    },
  },
  blogImage: {
    width: ['100%', '100%'],
    height: '100%',
    objectFit: 'cover',
    filter: 'brightness(0.8)',

    width: ['100%', '100%'],
    height: '100%',
    objectFit: 'cover',
    // filter: 'brightness(0.8)',
    transition: 'all ease-in-out 0.5s',

    '> div': {
      height: '100%',
    },
  },
  content: {
    width: ['100%', '100%'],
    padding: '1rem',
    position: 'absolute',
    bottom: '0%',
    left: '0%',
    // transform: 'translate(-50%,-50%)',
    color: 'light',
    zIndex: '1',
  },
  blogTitle: {
    fontSize: ['1.5rem'],
    display: 'flex',
    flexDirection: 'column',
    '::after': {
      content: "''",
      width: '40px',
      height: '4px',
      backgroundColor: 'primary',
      marginTop: '1rem',
    },
  },
  blogDescription: {
    fontSize: ['0.9rem'],
    fontWeight: 'normal',
  },

  details: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    borderTop: '1px solid white',
    padding: '0.5rem 0rem 0rem',
  },
  detail: {
    marginRight: '1rem',
    fontSize: '0.8rem',
  },
}

export const query = graphql`
  query blogQuery {
    allContentfulBlogPosts(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        id
        blogTitle
        categories
        blogDescription {
          childMarkdownRemark {
            html
          }
        }
        blogContent {
          childMarkdownRemark {
            html
            timeToRead
          }
        }
        updatedAt
        publishedDate(formatString: "DD MMM, YYYY")
        blogMainImage {
          gatsbyImageData
        }
      }
    }
  }
`

export default BlogMenu
