import React from 'react';
import { Box, Heading } from 'theme-ui';
import backgroundImageStyleObject from '../../helpers/backgroundImageStyleObject';
import { FaChevronCircleDown } from 'react-icons/fa';
export default function PageHero({ heroTitle, heroSubtitle, backgroundImage }) {
  const heroImageStyles = backgroundImageStyleObject(backgroundImage);

  const scrollDown = () => {
    let pageHeight = window.innerHeight;
    window.scrollTo({
      top: pageHeight * 0.825,
      behavior: 'smooth',
    });
  };

  return (
    <Box sx={{ ...styles.hero, ...heroImageStyles }}>
      <Heading sx={styles.heroTitle}>{heroTitle}</Heading>
      <Heading sx={styles.heroSubtitle}>{heroSubtitle}</Heading>
      <Box sx={styles.scollDown}>
        <FaChevronCircleDown onClick={scrollDown} />
      </Box>
    </Box>
  );
}

const styles = {
  hero: {
    height: '80vh',
    margin: [
      'calc(60.17px + 1rem) auto 1rem',
      '',
      'calc(86px + 1rem) auto 1rem',
    ],
    width: 'calc(100% - 2rem)',
    position: 'relative',
    borderRadius: '2px',

    img: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundAttachment: ['scroll', '', '', 'fixed'],
  },
  heroTitle: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
    width: '90%',
  },
  heroSubtitle: {
    color: 'primary',
    width: '90%',
  },
  scollDown: {
    position: 'absolute',
    bottom: '3rem',
    left: '50%',
    transform: 'translate(-50%)',
    color: 'primary',
    fontSize: '2rem',
    transition: 'all ease-in-out 0.7s',
    ':hover': {
      color: 'brandGreen',
    },
  },
};
